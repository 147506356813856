.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 15px 15px 0;
}

.checkbox__input {
  display: none;
}

.checkbox__check {
  display: block;
  position: relative;
  width: 26px;
  height: 26px;
  border-radius: 10px;
  border: 2px solid var(--gray-400);
  background: var(--gray-200);
}

.checkbox:hover .checkbox__check {
  border: 2px solid var(--gray-600);
  background: var(--gray-300);
}

.checkbox__input:checked + .checkbox__check {
  background: var(--yellow);
  border: none;
}

.checkbox:hover .checkbox__input:checked + .checkbox__check {
  background: var(--yellow-light);
}

.checkbox__check::after {
  content: '';
  display: none;
  position: absolute;
  width: 14px;
  height: 14px;
  z-index: 1;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.checkbox__input:checked + .checkbox__check::after {
  display: block;
}

.checkbox__text {
  margin-left: 10px;
  font-weight: 600;
}
