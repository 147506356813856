.grid {
  margin: 30px 0;
}

.grid__inner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: calc(var(--grid-gap) * 2);
}

@media screen and (max-width: 1023px) {
  .grid__inner {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
}
