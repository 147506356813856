.benefit {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 5px 20px 50px rgba(30, 30, 30, 0.1);
  height: 100%;
  grid-column: 1 span;
}

.benefit__mark {
  display: flex;
  justify-content: center;
  color: #fff;
  height: 70px;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  box-shadow: inset 0 -35px 15px -5px rgba(30, 30, 30, 0.25);
  padding-top: 12px;
  border-radius: 20px 20px 0 0;
}

.benefit__mark_blue {
  background: #4c8cef;
}

.benefit__mark_red {
  background: #f24b4b;
}

.benefit__mark_violet {
  background: #b556ff;
}

.benefit__mark_green {
  background: #50b72e;
}

.benefit__body {
  background: #fff;
  border-radius: 20px;
  margin-top: -20px;
  padding: 30px;
  height: 100%;
}

.benefit__icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  margin-bottom: 30px;
}

.benefit__icon-bg {
  width: 150px;
  height: 150px;
  background: rgb(255, 169, 0);
  background: linear-gradient(333deg, rgba(255, 169, 0, 0.37) 15%, rgba(255, 187, 0, 0.06) 64%, rgba(255, 204, 0, 0.08) 79%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 5px 2px 15px rgba(30, 30, 30, 0.1);
}

.benefit__icon {
  fill: var(--yellow);
}

.benefit__title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 15px;
}

.benefit__text {
  color: var(--gray-400);
  line-height: 1.2;
}

@media screen and (max-width: 767px) {
  .benefit {
    grid-column: 2 span;
  }

  .benefit__text {
    font-size: 15px;
  }

  .benefit__mark {
    font-size: 16px;
    height: 60px;
    padding-top: 10px;
  }

  .benefit__body {
    padding: 15px;
  }

  .benefit__icon-wrapper {
    height: 150px;
    margin-bottom: 15px;
  }

  .benefit__icon-bg {
    width: 125px;
    height: 125px;
  }

  .benefit__icon {
    width: 75px;
    height: 75px;
  }
}
