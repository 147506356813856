.grid-gallery {
  margin: 30px 0;
}

.grid-gallery__inner {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 30px;
}

.grid-gallery__img {
  box-shadow: 5px 20px 50px rgba(30, 30, 30, 0.1);
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: transform ease-in-out 0.2s;
}

.grid-gallery__item-1 {
  grid-area: 1 / 1 / 3 / 2;
}

.grid-gallery__item-2 {
  grid-area: 1 / 2 / 2 / 3;
  height: 270px;
}

.grid-gallery__item-3 {
  grid-area: 1 / 3 / 2 / 5;
  height: 270px;
}

.grid-gallery__item-4 {
  grid-area: 2 / 2 / 3 / 4;
  height: 270px;
}

.grid-gallery__item-5 {
  grid-area: 2 / 4 / 4 / 5;
}

.grid-gallery__item-6 {
  grid-area: 3 / 1 / 4 / 3;
  height: 270px;
}

.grid-gallery__item-7 {
  grid-area: 3 / 3 / 4 / 4;
  height: 270px;
}

@media screen and (max-width: 1199px) {
  .grid-gallery__inner {
    grid-gap: 20px;
  }

  .grid-gallery__item-2 {
    height: 220px;
  }

  .grid-gallery__item-3 {
    height: 220px;
  }

  .grid-gallery__item-4 {
    height: 220px;
  }

  .grid-gallery__item-6 {
    height: 220px;
  }

  .grid-gallery__item-7 {
    height: 220px;
  }
}

@media screen and (max-width: 1023px) {
  .grid-gallery__inner {
    grid-gap: 20px;
  }

  .grid-gallery__item-2 {
    height: 168px;
  }

  .grid-gallery__item-3 {
    height: 168px;
  }

  .grid-gallery__item-4 {
    height: 168px;
  }

  .grid-gallery__item-6 {
    height: 168px;
  }

  .grid-gallery__item-7 {
    height: 168px;
  }
}

@media screen and (min-width: 768px) {
  .grid-gallery__img:hover {
    transform: scale(1.01);
  }
}

@media screen and (max-width: 767px) {
  .grid-gallery {
    margin: 20px 0;
  }

  .grid-gallery__inner {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    grid-gap: 10px;
  }

  .grid-gallery__item-1,
  .grid-gallery__item-2,
  .grid-gallery__item-3,
  .grid-gallery__item-4,
  .grid-gallery__item-5,
  .grid-gallery__item-6,
  .grid-gallery__item-7 {
    height: auto;
    grid-area: auto;
    position: relative;
    padding-top: 100%;
  }

  .grid-gallery__img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
  }
}
