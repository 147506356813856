/* Переменные CSS */

:root {
  /* Цвета бренда */
  --green: #83cc21;
  --green-100: #4cca00;
  --yellow: #fc0;
  --yellow-light: #eab800;
  --orange: #fa6239;
  --blue: #008bff;

  /* Серые оттенки */
  --gray-100: #f7f7f7;
  --gray-200: #f1f1f1;
  --gray-250: #ebebeb;
  --gray-300: #e6e6e6;
  --gray-400: #999;
  --gray-500: #484848;
  --gray-550: #3f3f3f;
  --gray-600: #333;

  /* Сетка */
  --grid-gap: 15px;

  /* Стандартное скругление углов */
  --border-radius: 5px;
}

/* Другие главные стили */

html {
  background: #434343;
  -webkit-font-smoothing: antialiased !important;

  /* Cглаживание шрифтов на MAC OSX - ВНИМАНИЕ!!! Делают шрифт немного тоньше!!! */
  -moz-osx-font-smoothing: grayscale !important;
}

body {
  font-family: 'Roboto', sans-serif;
  min-height: 100%;
  color: var(--gray-550);
  background: var(--gray-100);
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

/* Настройка враппера */

.wrapper {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}

.wrapper_padding {
  padding: 0 15px;
}

/* Настройки брейкпоинтов враппера */
@media screen and (max-width: 1199px) {
  .wrapper {
    width: 960px;
  }

  .wrapper_padding {
    padding: 0 10px;
  }

  :root {
    --grid-gap: 10px;
  }
}

@media screen and (max-width: 1023px) {
  .wrapper {
    width: 744px;
  }

  .wrapper_padding {
    padding: 0 6px;
  }

  :root {
    --grid-gap: 6px;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    width: 100%;
  }

  .wrapper_padding {
    padding: 0 15px;
  }

  .wrapper_xs-full {
    padding: 0;
  }

  :root {
    --grid-gap: 5px;
  }
}

/* Скрытие блока в определенном диапазоне разрешений */

@media screen and (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .hidden-sm {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media screen and (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.gray-bg {
  background-color: var(--gray-200);
}

.white-bg {
  background: #fff;
}

.main {
  padding-top: 30px;
}
