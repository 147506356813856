.partners__slide {
  padding-top: 50%;
  position: relative;
}

.partners__slide-inner {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px;
  box-sizing: border-box;
}

.partners__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.partners__slider .swiper-slide {
  width: 70%;
}

@media screen and (max-width: 1199px) {
  .partners__slide-inner {
    padding: 50px;
  }
}

@media screen and (max-width: 1023px) {
  .partners__slide-inner {
    padding: 60px;
  }
}

@media screen and (max-width: 767px) {
  .partners__slide-inner {
    padding: 30px;
  }
}
