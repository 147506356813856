.credit-table {
  margin: 60px 0;
}

.credit-table__header {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.credit-table__body {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 30px;
}

.credit-table__title {
  width: 40%;
  font-size: 32px;
  font-weight: 600;
}

.credit-table__legend {
  text-align: center;
  font-weight: 400;
  color: var(--gray-400);
}

.credit-table__legend_1 {
  width: 10%;
}

.credit-table__legend_2 {
  width: 15%;
}

.credit-table__legend_3 {
  width: 20%;
}

.credit-table__legend_4 {
  width: 15%;
}

.credit-table__row {
  width: 100%;
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 5px 2px 30px rgba(30, 30, 30, 0.1);
}

.credit-table__logo {
  width: 20%;
  height: 98px;
  padding: 30px;
}

.credit-table__logo-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.credit-table__bank {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 20%;
  padding: 30px 15px 30px 30px;
}

.credit-table__name {
  color: var(--gray-400);
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 15px;
}

.credit-table__program {
  font-weight: 600;
  font-size: 18px;
}

.credit-table__cell {
  text-align: center;
  font-weight: 600;
}

.credit-table__cell_1 {
  width: 10%;
}

.credit-table__cell_2 {
  width: 15%;
}

.credit-table__cell_3 {
  width: 20%;
}

.credit-table__cell_4 {
  width: 15%;
}
