.about-company {
  margin: 30px 0 30px;
}

.about-company__wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
}

.about-company__benefits {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  background: #fff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 5px 20px 50px rgba(30, 30, 30, 0.1);
}

.about-company__text {
  font-size: 18px;
  line-height: 1.5;
  color: var(--gray-400);
}

.about-company__benefit {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-template-rows: 30px 30px;
  grid-gap: 5px;
}

.about-company__benefit-icon {
  grid-row: span 2;
  fill: var(--yellow);
  padding: 7px;
  border-radius: 10px;
  justify-self: center;
  align-self: center;
}

.about-company__benefit-title {
  color: var(--yellow);
  font-size: 16px;
  font-weight: 600;
  align-self: flex-end;
}

@media screen and (max-width: 1023px) {
  .about-company {
    margin: 20px 0 30px;
  }

  .about-company__wrapper {
    grid-template-columns: 1fr;
  }

  .about-company__benefits {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    padding: 30px;
  }

  .about-company__text {
    font-size: 16px;
    margin: 0 0 30px;
  }
}

@media screen and (max-width: 767px) {
  .about-company {
    margin: 30px 0 20px;
  }

  .about-company__benefits {
    grid-template-columns: 1fr;
    grid-gap: 0;
    padding: 10px;
  }

  .about-company__text {
    font-size: 16px;
    margin: 0 0 30px;
  }
}
