.complex {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  overflow: hidden;
  background: #fff;
  box-shadow: 5px 20px 50px rgba(30, 30, 30, 0.1);
  transition: all 0.3s ease;
}

.complex:hover {
  box-shadow: 5px 20px 40px rgba(30, 30, 30, 0.2);
}

.complex__img-wrapper {
  width: 100%;
  padding-top: 100%;
  position: relative;
}

.complex__img {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.complex__marks {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 100%;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  padding: 15px 15px 15px 0;
}

.complex__mark {
  display: inline-block;
  background: #4c8cef;
  color: #fff;
  padding: 7px 15px;
  margin-top: 10px;
  border-radius: 0 10px 10px 0;
  font-size: 14px;
  font-weight: 600;
}

.complex__mark:nth-of-type(2) {
  background: #f24b4b;
  color: #fff;
}

.complex__mark:nth-of-type(3) {
  background: #b556ff;
  color: #fff;
}

.complex__mark:nth-of-type(4) {
  background: #50b72e;
  color: #fff;
}

.complex__content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.complex__content {
  padding: 30px 30px 15px;
}

.complex__subtitle {
  color: var(--gray-400);
  margin-bottom: 10px;
}

.complex__title {
  font-size: 20px;
  font-weight: 800;
  margin-bottom: 10px;
  color: var(--gray-550);
}

.complex__title:hover {
  color: var(--yellow);
}

.complex__address {
  color: var(--gray-400);
  min-height: 32px;
}

.complex__table-wrapper {
  padding: 0 15px 20px;
}

.complex__table {
  background: var(--gray-100);
  color: var(--gray-400);
  width: 100%;
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 15px;
  border-collapse: collapse;
  overflow: hidden;
}

.complex__table-cell {
  padding: 12px 5px;
  white-space: nowrap;
}

.complex__table-cell:first-of-type {
  padding-left: 15px;
  font-weight: 600;
}

.complex__table-cell:last-of-type {
  padding-right: 15px;
}

.complex__table-row:not(:last-of-type) {
  border-bottom: 1px solid var(--gray-300);
}

.complex__table-row:not(.complex__table-row_sold):hover {
  background: var(--gray-300);
  color: var(--gray-550);
}

.complex__table-row_sold:hover {
  cursor: default;
}

.complex__price-wrapper {
  padding: 0 30px 25px;
}

.complex__count-objects {
  color: var(--gray-400);
  font-weight: 600;
  font-size: 21px;
  margin-bottom: 10px;
}

.complex__price {
  font-weight: 800;
  font-size: 22px;
  color: var(--gray-550);
}

@media screen and (max-width: 767px) {
  .complex__content {
    padding: 25px 25px 15px;
  }

  .complex__subtitle {
    font-size: 15px;
    margin-bottom: 7px;
  }

  .complex__title {
    font-size: 19px;
    margin-bottom: 7px;
  }

  .complex__title:hover {
    color: var(--yellow);
  }

  .complex__address {
    font-size: 15px;
    min-height: auto;
  }

  .complex__table-wrapper {
    padding: 0 10px 15px;
  }

  .complex__table {
    font-size: 14px;
  }

  .complex__price-wrapper {
    padding: 0 25px 20px;
  }

  .complex__count-objects {
    font-size: 17px;
    margin-bottom: 7px;
  }

  .complex__price {
    font-weight: 800;
    font-size: 20px;
    color: var(--gray-550);
  }

  .complex__mark {
    font-size: 13px;
  }
}
