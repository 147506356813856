.header-menu {
  background: rgba(63, 63, 63, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.5);
}

.header-menu__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  position: relative;
}

.header-menu__item {
  color: #fff;
  margin: 0 15px;
  font-weight: 600;
  font-size: 18px;
}

.header-menu__item:hover {
  color: var(--yellow);
}

.header-menu__phone {
  font-size: 24px;
  font-weight: 800;
  color: #fff;
}

.header-menu__phone:hover {
  color: var(--yellow);
}

.header-menu__hamburger-text {
  color: #fff;
  margin-left: 5px;
  font-weight: 600;
}

@media screen and (max-width: 1023px) {
  .header-menu {
    background: var(--gray-600);  
  }
  
  .header-menu__nav {
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 1;
    left: 0;
    top: -200%;
    background: var(--gray-600);
    border-radius: 0 0 10px 10px;
    padding: 15px;
    border-top: 2px solid var(--gray-500);
    transition: top 0.3s ease;
  }

  .header-menu__nav.active {
    top: 100%;
  }

  .header-menu__item {
    margin-bottom: 15px;
  }

  .header-menu__wrapper {
    height: 70px;
  }

  .header-menu__hamburger {
    margin-left: 30px;
  }

  .header-menu__logo {
    display: flex;
    align-items: center;
    height: 100%;
    background: var(--gray-600);
    flex-grow: 1;
    position: relative;
    z-index: 2;
  }

  .header-menu__logo-img {
    width: 80px;
  }

  .header-menu__phone {
    font-size: 20px;
    position: relative;
    z-index: 2;
  }
}

@media screen and (max-width: 767px) {
  .header-menu__nav {
    max-width: 290px;
  }

  .header-menu__phone {
    font-size: 16px;
  }

  .header-menu__hamburger {
    margin-left: 18px;
  }

  .header-menu__logo-img {
    width: 70px;
  }
}
