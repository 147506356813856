.footer__top {
  background: var(--gray-550);
}

.footer__top-inner {
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 30px 0;
}

.footer__bottom {
  background: var(--gray-600);
  color: #fff;
}

.footer__warning {
  font-size: 13px;
  color: var(--gray-400);
  padding: 20px 0;
  line-height: 1.2;
}

.footer__copyright {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  font-size: 14px;
}

.footer__politics {
  display: flex;
}

.footer__politics-link {
  color: var(--yellow);
}

.footer__politics-link:first-of-type {
  margin-right: 30px;
}

.footer__politics-link:hover {
  text-decoration: underline;
}

.footer__logo {
  margin-bottom: 30px;
}

.footer__phone {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.footer__phone-link {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 5px;
}

.footer__phone-link:hover {
  color: var(--yellow);
}

.footer__callback-btn {
  margin-bottom: 30px;
}

.footer__socials {
  display: flex;
  justify-content: space-between;
}

.footer__social-link:not(:last-of-type) {
  margin-right: 15px;
}

.footer__social-link-icon {
  fill: var(--gray-400);
}

.footer__social-link:hover .footer__social-link-icon {
  fill: var(--yellow);
}

.footer__main-menu {
  display: flex;
  flex-direction: column;
}

.footer__menu-link {
  color: #fff;
}

.footer__menu-link:hover {
  color: var(--yellow);
}

.footer__menu-link_bold {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}

.footer__menu-link_regular {
  font-size: 15px;
  margin-bottom: 21px;
}

.footer__address {
  font-size: 14px;
  line-height: 1.2;
}

.footer__address:not(:last-of-type) {
  margin-bottom: 30px;
}

.footer__address-title {
  color: var(--gray-400);
  margin-bottom: 10px;
}

.footer__address-text {
  font-size: 14px;
  margin-bottom: 10px;
}

@media screen and (max-width: 1023px) {
  .footer__col-2 {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .footer__top-inner {
    display: flex;
    flex-direction: column;
  }

  .footer__col-1 {
    margin-bottom: 20px;
  }

  .footer__col-3 {
    margin-bottom: 30px;
  }

  .footer__phone {
    align-items: flex-start;
    margin-bottom: 15px;
  }

  .footer__callback-btn {
    margin-bottom: 20px;
    max-width: 290px;
  }

  .footer__socials {
    justify-content: flex-start;
  }

  .footer__copyright {
    flex-direction: column-reverse;
  }

  .footer__politics {
    flex-direction: column;
  }

  .footer__politics-link {
    margin-bottom: 10px;
  }

}
