.filter-square {
  display: flex;
}

.filter-square__label:not(:last-of-type) {
  margin-right: 12px;
}

.filter-square__checkbox {
  display: none;
}

.filter-square__btn {
  display: flex;
  align-items: center;
  background: var(--gray-200);
  padding: 0 15px;
  height: 40px;
  min-width: 40px;
  border-radius: 10px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
}

.filter-square__btn:hover {
  background: var(--gray-300);
}

.filter-square__checkbox:checked + .filter-square__btn {
  background: var(--yellow);
}

.filter-square__checkbox:checked + .filter-square__btn:hover {
  background: var(--yellow-light);
}

@media screen and (max-width: 767px) {
  .filter-square {
    flex-wrap: wrap;
  }

  .filter-square__label {
    margin-bottom: 12px;
  }
}
