.header {
  background: url('../img/static/header-bg.jpg') 50% no-repeat;
  background-size: cover;
}

.header__wrapper {
  background: rgba(63, 63, 63, 0.5);
}

.header__inner {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -25%);
  width: 100%;
}

.header__content::before {
  content: '';
  z-index: -1;
  display: block;
  width: 500px;
  height: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 100px 50px rgba(63, 63, 63, 0.4);
}

.header__content-inner {
  display: flex;
  justify-content: center;
}

.header__title {
  display: flex;
  flex-direction: column;
  color: #fff;
  margin-bottom: 50px;
  text-shadow: -3px 2px 1px rgba(63, 63, 63, 0.3);
}

.header__title-row {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
}

.header__title-column {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  padding-bottom: 1px;
}

.header__subtitle {
  font-size: 30px;
  font-weight: 800;
  text-transform: uppercase;
  text-align: right;
  line-height: 0.8;
  color: var(--yellow);
  margin-top: 7px;
}

.header__title-main {
  font-size: 72px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1;
  display: block;
  text-align: left;
}

.header__note {
  width: 100%;
  font-size: 25.4px;
  font-weight: 500;
  white-space: nowrap;
  text-align: right;
}

.header__widget {
  position: absolute;
  width: 100%;
  z-index: 2;
  padding-left: 25px;
}

.header__scroll {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header__scroll-icon {
  fill: #fff;
}

.header__scroll-angle-icon {
  fill: var(--yellow);
  transform: translateY(20px);
  animation: scroll-angel 1s linear infinite;
}

.header__btn {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}

@keyframes scroll-angel {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

@media screen and (max-width: 1199px) {
  .header__title {
    margin-bottom: 30px;
  }

  .header__subtitle {
    font-size: 24px;
  }

  .header__title-main {
    font-size: 60px;
  }

  .header__note {
    font-size: 21px;
  }

  .header__scroll-icon {
    width: 55px;
    height: 55px;
  }

  .header__scroll-angle-icon {
    width: 25px;
    height: 25px;
  }
}

@media screen and (max-width: 767px) {
  .header__content {
    top: auto;
    bottom: 0;
    transform: translate(-50%, 0%);
    padding-bottom: 15px;
  }

  .header__title-row {
    display: flex;
    flex-direction: column;
  }

  .header__title-column {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .header__title {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
  }

  .header__subtitle {
    margin-right: 10px;
  }

  .header__title-main {
    font-size: 42px;
  }

  .header__note {
    font-size: 18px;
    white-space: normal;
    line-height: 1.2;
  }
}

@media screen and (max-width: 767px) and (min-height: 750px) {
  .header__content {
    top: 50%;
    transform: translate(-50%, -25%);
    padding-bottom: 0;
  }
}
