.section-title {
  margin: 60px 0 30px;
}

.section-title__title {
  font-size: 32px;
  font-weight: 600;
}

.section-title__subtitle {
  font-size: 20px;
  color: var(--gray-400);
  font-weight: 600;
  margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
  .section-title {
    margin: 40px 0 20px;
  }

  .section-title__title {
    font-size: 24px;
  }

  .section-title__subtitle {
    font-size: 18px;
  }
}
