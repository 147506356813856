.grid-complex {
  margin: 30px 0;
}

.grid-complex_view-more {
  overflow: hidden;
  position: relative;
  margin: 0;
}

.grid-complex__inner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

.grid-complex_view-more .grid-complex__inner {
  margin-bottom: -150px;
}

.grid-complex__shadow {
  width: 100%;
  height: 570px;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 70px;
  background: rgb(247, 247, 247);
  background: linear-gradient(180deg, rgba(247, 247, 247, 0) 25%, rgba(247, 247, 247, 1) 85%);
}

@media screen and (max-width: 1199px) {
  .grid-complex__inner {
    grid-gap: 20px;
  }
}

@media screen and (max-width: 1023px) {
  .grid-complex__inner {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 767px) {
  .grid-complex {
    margin: 20px 0;
  }

  .grid-complex__inner {
    grid-template-columns: repeat(1, 1fr);
  }
}
