.split-grid {
  margin: 30px 0;
}

.split-grid__inner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .split-grid__inner {
    grid-gap: 20px;
  }
}

@media screen and (max-width: 1023px) {
  .split-grid {
    margin: 30px 0;
  }

  .split-grid__inner {
    grid-template-columns: repeat(2, 1fr);
  }
}
