.work-stage {
  position: relative;
  background: #fff;
  border-radius: 20px;
  box-shadow: 5px 20px 50px rgba(30, 30, 30, 0.1);
  grid-column: 1 span;
}

.work-stage__count {
  position: absolute;
  z-index: 2;
  top: -10px;
  left: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-size: 18px;
  color: #fff;
  padding: 10px 15px;
  border-radius: 100px;
  background: var(--yellow);
}

.work-stage__count::after {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 150%;
  height: 150%;
  border-radius: 100px;
  background: rgba(255, 204, 0, 0.3);
}

.work-stage__top {
  padding-top: 56.25%;
  position: relative;
  overflow: hidden;
  border-radius: 20px 20px 0 0;
}

.work-stage__img {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.work-stage__content {
  padding: 30px;
}

.work-stage__title {
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 15px;
}

.work-stage__text {
  color: var(--gray-400);
  line-height: 1.2;
}

@media screen and (max-width: 767px) {
  .work-stage {
    grid-column: 2 span;
  }

  .work-stage__content {
    padding: 15px;
  }
}
