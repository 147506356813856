.popup.fancybox-content {
  min-width: 300px;
  padding: 0;
  border-radius: 10px;
}

.popup__inner {
  display: flex;
  align-items: stretch;
}

.popup__form {
  width: 380px;
  padding: 30px;
}

.popup__image {
  display: flex;
  align-items: center;
  width: 380px;
  background: url('../img/static/callback-bg.jpeg') no-repeat bottom right;
  background-size: contain;
}

.popup__info {
  font-size: 25px;
  font-weight: 600;
}

.popup__title {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 30px;
  text-align: center;
}

.popup__input {
  margin-bottom: 30px;
  position: relative;
}

.popup__controls {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup__politics {
  font-size: 12px;
  color: var(--gray-400);
  margin-top: 15px;
  text-align: center;
  line-height: 1.3;
}

.popup__politics-link {
  color: var(--yellow);
}

.popup__politics-link:hover {
  text-decoration: underline;
}

.popup__form-inner {
  display: block;
}

.popup__form-inner_hidden {
  display: none;
}

.popup__success {
  display: none;
  height: 332px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.popup__success_visible {
  display: flex;
}

.popup__success-text {
  font-size: 28px;
  font-weight: 600;
}

.popup__success-icon {
  display: block;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 30px;
  border: 14px solid #4cca00;
  fill: #4cca00;
  border-radius: 50%;
}

.popup__input__error {
  display: none;
  position: absolute;
  bottom: -15px;
  width: 100%;
  font-size: 12px;
  color: #f62d2d;
  padding-left: 10px;
}

.popup__input__error_active {
  display: block;
}

.popup__whatsup {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  background: #44d740;
  padding: 0 5px 0 15px;
}

.popup__whatsup:hover {
  background: #3bbe38;
}

.popup__whatsup-text {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-right: 5px;
}

.popup__whatsup-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
}

.popup__whatsup-icon-svg {
  fill: #fff;
}

.popup__text-information {
  max-width: 760px;
  padding: 60px 30px 30px;
  line-height: 1.2;
  text-align: justify;
}

.popup__text-information-title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}

.popup__text-information > ul {
  list-style: none;
  padding: 0;
}

.popup__text-information > ul > li {
  margin: 15px 0 30px;
}

.popup__text-information > ul > li > ul {
  list-style: none;
  padding-left: 15px;
}

.popup__text-information > ul > li > ul ul {
  list-style: disc;
  padding-left: 30px;
  margin: 5px 0;
}

.popup__text-information > ul > li > ul > li {
  margin: 15px 0;
}

.popup__text-information > ul > li > ul ul > li {
  margin: 5px 0;
}

.popup__text-information a {
  color: var(--gray-550);
  font-weight: 600;
}

.popup__text-information a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .popup__image {
    display: none;
  }

  .popup__form {
    width: 308px;
    padding: 30px 30px 60px 30px;
  }

  .popup__whatsup {
    width: 100%;
    justify-content: center;
    bottom: 0;
    right: 0;
    border-radius: 0;
  }

  .popup__text-information {
    width: 308px;
    padding: 40px 15px 30px;
    font-size: 14px;
  }

  .popup__text-information-title {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .popup__text-information > ul > li {
    margin: 10px 0 20px;
  }

  .popup__text-information > ul > li > ul {
    padding-left: 10px;
  }

  .popup__text-information > ul > li > ul ul {
    padding-left: 17px;
  }

  .popup__text-information > ul > li > ul > li {
    margin: 10px 0;
  }
}
