.input {
  width: 100%;
}

.input__input {
  display: block;
  width: 100%;
  border: none;
  background: var(--gray-200);
  border-radius: 10px;
  height: 40px;
  padding: 5px 15px;
  color: var(--gray-550);
  font-weight: 600;
  font-size: 16px;
}

.input__input:hover {
  background: var(--gray-250);
}

.input__input:focus {
  background: var(--gray-250);
  color: var(--gray-550);
}

.input__input::placeholder {
  font-weight: 600;
  color: var(--gray-400);
}
