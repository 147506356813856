.filter-range {
  display: flex;
  align-items: center;
}

.filter-range__window {
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: center;
  background: var(--gray-200);
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  cursor: text;
}

.filter-range__window:focus-within {
  background: var(--gray-250);
}

.filter-range__window:first-of-type {
  border-right: 1px solid var(--gray-300);
  border-radius: 10px 0 0 10px;
}

.filter-range__window:last-of-type {
  border-radius: 0 10px 10px 0;
}

.filter-range__unit {
  padding: 0 7px;
  color: var(--gray-400);
}

.filter-range__input {
  min-width: 10px;
  border-bottom: 1px solid var(--gray-300);
  max-width: 110px;
  overflow: hidden;
  white-space: nowrap;
  color: var(--gray-550);
}

.filter-range__input:hover,
.filter-range__input:focus {
  border-color: var(--gray-550);
}

.filter-range__separator {
  cursor: default;
  font-size: 18px;
  color: var(--gray-400);
  font-weight: 600;
}
