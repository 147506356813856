.info-slider__inner {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 20px;
  box-shadow: 5px 2px 30px rgba(30, 30, 30, 0.1);
}

.info-slider .swiper-pagination {
  display: none;
}

.info-slider__container.swiper-container {
  width: 100%;
  height: 500px;
}

.info-slider__slide {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-left: 120px;
  box-sizing: border-box;
}

.info-slider__title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 10px;
}

.info-slider__subtitle {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 30px;
}

.info-slider__btn {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: var(--yellow);
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 5px 2px 30px rgba(30, 30, 30, 0.1);
}

.info-slider__btn:hover {
  background: var(--yellow-light);
}

.info-slider__btn_prev {
  left: 40px;
}

.info-slider__btn_next {
  right: 40px;
}

.info-slider__btn-icon {
  fill: #fff;
}

.info-slider__btn_prev .info-slider__btn-icon {
  transform: rotate(90deg);
}

.info-slider__btn_next .info-slider__btn-icon {
  transform: rotate(-90deg);
}

.info-slider__counter {
  display: flex;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  top: 80px;
  background: var(--yellow);
  border-radius: 100px;
  padding: 10px 20px;
  font-weight: 600;
  color: #fff;
  box-shadow: 5px 2px 30px rgba(30, 30, 30, 0.1);
}

.info-slider__counter-current {
  margin: 0 3px;
}

.info-slider__counter-total {
  margin-left: 3px;
}

@media screen and (max-width: 1023px) {
  .info-slider__container.swiper-container {
    height: 350px;
  }

  .info-slider__counter {
    top: 35px;
  }
}

@media screen and (max-width: 767px) {
  .info-slider__container.swiper-container {
    width: 100%;
    height: 290px;
  }

  .info-slider__slide {
    padding: 0 15px 15px;
    justify-content: flex-end;
  }

  .info-slider__counter {
    top: 15px;
    font-size: 14px;
  }

  .info-slider__title {
    font-size: 24px;
  }

  .info-slider__subtitle {
    font-size: 18px;
    margin-bottom: 35px;
  }

  .info-slider__btn {
    top: 15px;
    width: 34px;
    height: 34px;
    transform: translateY(0);
  }

  .info-slider__btn_prev {
    left: 15px;
  }

  .info-slider__btn_next {
    right: 15px;
  }
}
