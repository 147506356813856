.filter {
  display: flex;
  flex-direction: column;
}

.filter__title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
}
