.select {
  position: relative;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
}

.select__inner {
  position: absolute;
  width: 100%;
  z-index: 2;
  background: var(--gray-200);
  border-radius: 10px;
  overflow: hidden;
}

.select__dropdown {
  display: none;
}

.select__dropdown-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  padding: 0 15px;
  cursor: pointer;
  border-radius: 10px 10px 0 0;
}

.select__dropdown-btn:hover {
  background: var(--gray-300);
}

.select__dropdown-icon {
  fill: var(--gray-400);
  transition: transform 0.2s ease;
}

.select__dropdown-icon_open {
  transform: scaleY(-1);
}

.select__list {
  max-height: 162px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--gray-300);
}

.select__radio {
  display: none;
}

.select__item-text {
  display: block;
  padding: 10px 15px;
  cursor: pointer;
}

.select__item-text:hover {
  background: var(--gray-300);
}
