.hamburger {
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 0;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
}

.hamburger_catalog-btn {
  font-size: 8px;
}

.hamburger_header {
  font-size: 8px;
}

.hamburger-box {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 1.5em;
}

.hamburger-inner {
  top: 50%;
  display: block;
  margin-top: -0.125em;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  position: absolute;
  width: 2em;
  height: 0.25em;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: transform;
  border-radius: 0;
  background-color: var(--gray-600);
}

.hamburger_catalog-btn .hamburger-inner,
.hamburger_catalog-btn .hamburger-inner::before,
.hamburger_catalog-btn .hamburger-inner::after {
  background: #fff;
}

.hamburger-inner::before,
.hamburger-inner::after {
  display: block;
  content: "";
}

.hamburger-inner::before {
  top: -0.625em;
}

.hamburger-inner::after {
  bottom: -0.625em;
}

.hamburger_header .hamburger-inner,
.hamburger_header .hamburger-inner::before,
.hamburger_header .hamburger-inner::after {
  background-color: #fff;
}

.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition-duration: 0.13s;
}

.hamburger--collapse .hamburger-inner::after {
  top: -1.25em;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: var(--gray-600);
}

.hamburger_catalog-btn.is-active .hamburger-inner,
.hamburger_catalog-btn.is-active .hamburger-inner::before,
.hamburger_catalog-btn.is-active .hamburger-inner::after {
  background-color: #fff;
}

.catalog-btn:hover .hamburger-inner,
.catalog-btn:hover .hamburger-inner::before,
.catalog-btn:hover .hamburger-inner::after {
  background: #fff;
}

.hamburger--collapse.is-active .hamburger-inner {
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, -0.625em, 0) rotate(-45deg);
}

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
  opacity: 0;
}

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: rotate(-90deg);
}

.hamburger.hamburger_header.is-active .hamburger-inner,
.hamburger.hamburger_header.is-active .hamburger-inner::before,
.hamburger.hamburger_header.is-active .hamburger-inner::after {
  background-color: #fff;
}
