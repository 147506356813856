.info-banner {
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  box-shadow: 5px 2px 30px rgba(30, 30, 30, 0.1);
  grid-column: 2 span;
  position: relative;
  padding: 65px 0;
}

.info-banner.info-banner_mini {
  grid-column: 1 span;
}

.info-banner.info-banner_mini-sm-fw {
  grid-column: 1 span;
}

.info-banner__title {
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 30px;
  text-align: center;
  line-height: 1.2;
  min-height: 66px;
  color: var(--gray-550);
}

.info-banner__top {
  position: relative;
  height: 52px;
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.info-banner__tooltip {
  background: var(--yellow);
  border-radius: 100px;
  padding: 10px 20px;
  font-weight: 600;
  color: #fff;
  box-shadow: 5px 2px 30px rgba(30, 30, 30, 0.1);
}

.info-banner__bell {
  position: absolute;
  bottom: 0;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-left: -25px;
  transform: rotate(-20deg);
  animation: rotate-bell 0.3s linear infinite;
}

.info-banner__bell-icon {
  fill: var(--yellow);
}

@keyframes rotate-bell {
  0% {
    transform: rotate(-20deg);
  }

  50% {
    transform: rotate(-35deg);
  }

  100% {
    transform: rotate(-20deg);
  }
}

.info-banner__whatsapp {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  background: #44d740;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.info-banner__whatsapp:hover {
  background: #37a733;
}

.info-banner__whatsapp::after {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  width: 170px;
  height: 170px;
  background: rgba(68, 215, 64, 0.2);
  border-radius: 50%;
  animation: whatsapp-pulse 1s linear infinite;
}

@keyframes whatsapp-pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }

  50% {
    transform: translate(-50%, -50%) scale(1.05);
  }

  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

.info-banner__whatsapp::before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 120px;
  background: rgba(68, 215, 64, 0.3);
  border-radius: 50%;
  animation: whatsapp-scale 1s linear infinite;
}

@keyframes whatsapp-scale {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }

  70% {
    opacity: 0.9;
  }

  100% {
    transform: translate(-50%, -50%) scale(1.4);
    opacity: 0;
  }
}

.info-banner__whatsapp-icon {
  fill: #fff;
  width: 70px;
  height: 70px;
  animation: rotate-whatsapp 3s linear infinite;
}

@keyframes rotate-whatsapp {
  0% {
    transform: rotate(0deg);
  }

  39% {
    transform: rotate(0deg) scale(1);
  }

  40% {
    transform: rotate(-13deg) scale(1.03);
  }

  42% {
    transform: rotate(13deg) scale(1.06);
  }

  44% {
    transform: rotate(-13deg) scale(1.1);
  }

  46% {
    transform: rotate(13deg) scale(1.1);
  }

  48% {
    transform: rotate(-13deg) scale(1.1);
  }

  50% {
    transform: rotate(13deg) scale(1.1);
  }

  52% {
    transform: rotate(-13deg) scale(1.1);
  }

  54% {
    transform: rotate(13deg) scale(1.1);
  }

  56% {
    transform: rotate(-13deg) scale(1.1);
  }

  58% {
    transform: rotate(13deg) scale(1.06);
  }

  60% {
    transform: rotate(-13deg) scale(1.03);
  }

  61% {
    transform: rotate(0deg) scale(1);
  }

  100% {
    transform: rotate(0deg);
  }
}

.info-banner__whatsapp-push {
  position: absolute;
  z-index: 100;
  top: 3px;
  right: 3px;
  background: #ff3d3d;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  color: #fff;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 1px 5px rgba(30, 30, 30, 0.5);
}

@media screen and (max-width: 1199px) {
  .info-banner {
    padding: 45px 0;
  }

  .info-banner__title {
    font-size: 28px;
    margin-bottom: 20px;
  }

  .info-banner__top {
    margin-bottom: 20px;
  }

  .info-banner__whatsapp {
    width: 100px;
    height: 100px;
  }

  .info-banner__whatsapp::after {
    width: 140px;
    height: 140px;
  }

  .info-banner__whatsapp::before {
    width: 100px;
    height: 100px;
  }

  .info-banner__whatsapp-icon {
    width: 50px;
    height: 50px;
  }

  .info-banner__whatsapp-push {
    width: 25px;
    height: 25px;
  }
}

@media screen and (max-width: 1023px) {
  .info-banner {
    grid-column: 2 span;
  }

  .info-banner.info-banner_mini {
    grid-column: 1 span;
  }

  .info-banner.info-banner_mini-sm-fw {
    grid-column: 2 span;
  }

  .info-banner-mini__title {
    font-size: 32px;
  }
}

@media screen and (max-width: 767px) {
  .info-banner__title {
    font-size: 22px;
  }

  .info-banner__tooltip {
    font-size: 14px;
  }

  .info-banner.info-banner_mini {
    grid-column: 2 span;
  }
}
