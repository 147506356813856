.filters-grid {
  margin: 30px 0 60px;
}

.filters-grid__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

.filters-grid__result-btn {
  align-self: flex-end;
}

@media screen and (max-width: 1169px) {
  .filters-grid__wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 767px) {
  .filters-grid__wrapper {
    grid-template-columns: 1fr;
  }
}
