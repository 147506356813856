.btn {
  display: block;
  background: var(--yellow);
  color: var(--gray-550);
  border-radius: 20px;
  text-transform: uppercase;
  text-align: center;
  box-shadow: 5px 2px 30px rgba(30, 30, 30, 0.1);
  white-space: nowrap;
}

.btn:hover {
  background: var(--yellow-light);
}

.btn_std {
  padding: 11px 15px;
  font-size: 16px;
}

.btn_medium {
  padding: 18px 26px;
  font-weight: 600;
  font-size: 16px;
}

.btn_large {
  padding: 22px 55px;
  font-size: 20px;
  font-weight: 800;
}

.btn_full-width {
  width: 100%;
}

.btn_round {
  border-radius: 100px;
}

@media screen and (max-width: 1199px) {
  .btn_large {
    padding: 18px 46px;
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .btn_medium {
    padding: 13px 20px;
    font-weight: 600;
    font-size: 14px;
  }
}
